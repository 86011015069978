import { render, staticRenderFns } from "./GDPR.vue?vue&type=template&id=fd7db534&scoped=true&"
import script from "./GDPR.vue?vue&type=script&lang=js&"
export * from "./GDPR.vue?vue&type=script&lang=js&"
import style0 from "./GDPR.vue?vue&type=style&index=0&id=fd7db534&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd7db534",
  null
  
)

export default component.exports