<template>
  <div>
    <h1 class="text-white mb-10">{{ $t("GDPR.TITLE") }}</h1>

    <div class="card card-custom gutter-b card-stretch mt-10">
      <!--begin::Header-->

      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body d-flex flex-column p-8">
        <!--begin::Chart-->
        <div class="text-justify" v-html="$t('GDPR.TEXT')"></div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<style scoped>
.bg-translucent {
  background: rgba(255, 255, 255, 0.8);
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  methods: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, []);
  },
};
</script>
